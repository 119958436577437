/* eslint-disable no-undef */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Grid2 } from '@mui/material';

import Button from '../components/ButtonS';
import Loader from '../components/Loader';
import Title from '../components/Title';

import { fetchApiMethods } from '../api/getMethods';

const Export = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleDownload = async () => {
    setLoading(true);
    const api = await fetchApiMethods();
    const buffer = await api.exchange.download();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const filename = 'MyActivity.xlsx';
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Grid2 container spacing={2} justifyContent="center">
      <Title text={<FormattedMessage id="Export" />} />
      <Grid2 item="true" size={12} textAlign="center">
        <Button onClick={handleDownload}>
          {<FormattedMessage id="Save" />}
        </Button>
      </Grid2>
      <Grid2 item="true" size={12} textAlign="center">
        <Button onClick={() => navigate('/home')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default Export;

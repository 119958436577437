import React from 'react';
import { Grid2, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
const ImportSuccessMessage = ({ message, counts }) => {
  return (
    <Grid2 item="true" xs={12} paddingTop={'12px'}>
      <Typography color={'naval'} fontWeight="bold">
        {message}
      </Typography>
      <Typography color={'naval'} paddingTop={2}>
        <FormattedMessage id="Sections" />: {counts.sections}
      </Typography>
      <Typography color={'naval'}>
        <FormattedMessage id="Items" />: {counts.items}
      </Typography>
      <Typography color={'naval'}>
        <FormattedMessage id="Values" />: {counts.values}
      </Typography>
    </Grid2>
  );
};

export default ImportSuccessMessage;

// Common packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material-UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// Shared Utils
import getDisplayedDays from '../../shared/utils/getDisplayedDays';
import errorMessageHandler from '../../shared/utils/errorMessageHandler';

import css from '../../themes/styles.js';

const DoneMissed = ({ titleMissed, valueMissed, titleDone, valueDone }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const fontSize = '16px';

  const [, setError] = useState('');
  const [, setLoading] = useState(true);
  const [doneDaysTitle, setDoneDaysTitle] = useState('');
  const [missedDaysTitle, setMissedDaysTitle] = useState('');

  const fetchData = async () => {
    try {
      // TODO DEBUG REMOVE A BIT LATER
      console.debug('valueDone', getDisplayedDays(valueDone, intl, false));
      // #############################

      setDoneDaysTitle(getDisplayedDays(valueDone, intl, false));
      setMissedDaysTitle(getDisplayedDays(valueMissed, intl, false));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(error));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!valueDone && !valueMissed) return <></>;

  return (
    <Grid
      container
      textAlign={'center'}
      sx={{ marginBottom: '16px', marginTop: '16px', minHeight: '75px' }}
    >
      <Grid item xs={1} />
      <Grid item xs={5} sx={css.paddingRight}>
        {!!valueMissed && (
          <div style={{ paddingRight: '20px' }}>
            <Typography color="details" sx={{ fontSize }}>
              {titleMissed}
            </Typography>
            <Typography color="min" sx={{ fontSize, fontWeight: 'bold' }}>
              {missedDaysTitle}
            </Typography>
          </div>
        )}
      </Grid>
      <Grid item xs={5} sx={css.paddingLeft}>
        {!!valueDone && (
          <div>
            <Typography color="details" sx={{ fontSize }}>
              {titleDone}
            </Typography>
            <Typography color="max" sx={{ fontSize, fontWeight: 'bold' }}>
              {doneDaysTitle}
            </Typography>
          </div>
        )}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default DoneMissed;

const getTranslationId = (number, key) => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return key;
  } else if (
    number % 10 >= 2 &&
    number % 10 <= 4 &&
    (number % 100 < 10 || number % 100 >= 20)
  ) {
    return `${key}s`;
  } else {
    return `${key}s2`;
  }
};

const getDisplayedDays = (inputDays, intl, full = true) => {
  // full means format: "Y years M months D days" instead of only "D days"
  if (inputDays === 0) return '';
  const years = full && Math.floor(inputDays / 365);
  const remainingDaysAfterYears = full && inputDays % 365;
  const months = full && Math.floor(remainingDaysAfterYears / 30);
  const days = remainingDaysAfterYears % 30 || (!full && inputDays);
  const yearText =
    years > 0
      ? `${years} ${intl.formatMessage({ id: getTranslationId(years, 'year') })}`
      : '';
  const monthText =
    months > 0
      ? `${months} ${intl.formatMessage({ id: getTranslationId(months, 'month') })}`
      : '';
  const dayText =
    days > 0
      ? `${days} ${intl.formatMessage({ id: getTranslationId(days, 'day') })}`
      : '';
  const fullDaysText =
    inputDays > 0 && inputDays !== days
      ? `${inputDays} ${intl.formatMessage({ id: getTranslationId(inputDays, 'day') })}`
      : '';
  const fullDayString = [yearText, monthText, dayText]
    .filter(Boolean)
    .join(' ');
  const result =
    inputDays > 0 && inputDays !== days
      ? `${fullDayString} (${fullDaysText})`
      : fullDayString;
  return result;
};

export default getDisplayedDays;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import Button from '../components/ButtonS.jsx';
import Loader from '../components/Loader.jsx';

import { UserContext } from '../context/user.js';

const Statistics = ({ api, premium, updatedDetailsAt }) => {
  const navigate = useNavigate();
  const { autoSchedule, setAutoSchedule } = React.useContext(UserContext);

  const [days, setDays] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scheduledDate, setScheduledDate] = useState();
  const [updatedDate, setUpdatedDate] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const scheduled = new Date(updatedDetailsAt) || new Date();
        if (premium) {
          if (autoSchedule) setDisabled(true);
          updatedDetailsAt && scheduled.setDate(scheduled.getDate() + 1);
        } else {
          scheduled.setDate(scheduled.getDate() + 7);
          // scheduled.setMonth(scheduled.getMonth() + 1);
        }

        updatedDetailsAt &&
          setUpdatedDate(
            new Date(updatedDetailsAt).toISOString().split('T')[0],
          );

        const scheduledDate = scheduled.toISOString().split('T')[0];
        setScheduledDate(
          scheduledDate === '1970-02-01' || scheduledDate === '1970-01-01'
            ? undefined
            : scheduledDate,
        );
        setDays(days);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate(`/error`);
      }
    }
    fetchData();
  }, []);

  const handleCheckBoxClick = async () => {
    setAutoSchedule(!autoSchedule);
    setDisabled(!autoSchedule);
    // if (days === 0) setDisabled(true)
  };

  const handleUpdateNowClick = async () => {
    setDisabled(true);
    setUpdatedDate(null);
    const result = await api.user.updateItemsDetails();
    const updatedAt = new Date(result.updatedAt).toISOString().split('T')[0];
    setUpdatedDate(updatedAt);
  };

  if (loading) return <Loader />;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: '20px', marginBottom: '20px' }}
    >
      <Paper elevation={7} style={{ padding: '10px', width: '300px' }}>
        <Typography variant="h7">
          <FormattedMessage id="Statistics" />
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: '10px' }}
        >
          {<FormattedMessage id="LatestUpdate" />}:{' '}
          {updatedDate || '----------'}
        </Typography>
        {premium ? (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              style={{ marginTop: '10px' }}
              onClick={handleUpdateNowClick}
            >
              {<FormattedMessage id="UpdateNow" />}
            </Button>
            <FormControlLabel
              control={<Checkbox checked={autoSchedule} />}
              label={<FormattedMessage id="AutomaticUpdate" />}
              style={{ marginTop: '10px' }}
              onClick={handleCheckBoxClick}
            />
          </>
        ) : null}
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: '10px' }}
        >
          {<FormattedMessage id="ScheduledUpdate" />}:{' '}
          {scheduledDate || '----------'}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Statistics;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Grid, ListItem, ListItemText, Paper } from '@mui/material';

import EditValue from '../components/EditValue';
import Error from '../components/Error.jsx';
import DynamicFontText from '../components/DynamicFontText';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import TwoButtons from '../components/TwoButtons.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler';
import validNumberValue from '../shared/utils/validNumberValue';

import { fetchApiMethods } from '../api/getMethods';

const Value = () => {
  const navigate = useNavigate();
  const { itemId, itemTitle, valueType, valueId, form, createdAt } =
    useParams();
  const [api, setApi] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [titles, setTitles] = useState([]);
  const [value, setValue] = useState('');
  const [isText, setIsText] = useState(false);

  React.useEffect(() => {
    async function fetchData() {
      const api = await fetchApiMethods();
      setApi(api);
      let validValue;
      let textValue;
      try {
        if (valueId) {
          const { value } = await api.value.get({ id: valueId });
          validValue = value.value;
          textValue = validValue;
        } else {
          // TODO That is not good decision to get value for each item.
          const { lastValue } = await api.item.getLastValue({ id: itemId });
          validValue = lastValue;
        }
        //  && !validNumberValue(validValue)
        if (valueType === 'text') {
          if (!valueId) {
            const result = await api.value.getTitles({ itemId });
            setTitles(result.titles);
          }
          setIsText(true);
          setValue(textValue || '');
        } else {
          setValue(validNumberValue(validValue) || 10);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(errorMessageHandler(error));
      }
    }
    fetchData();
  }, []);

  const handleAddTitle = async (title) => {
    try {
      setLoading(true);
      await api.value.create({ value: title, itemId, createdAt });
      setLoading(false);
      handleBackClick();
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  const handleAddClick = async () => {
    try {
      if (value > 0 || value.length > 0) {
        setLoading(true);
        await api.value.create({ value, itemId, createdAt });
        setLoading(false);
        handleBackClick();
      }
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  const handleBackClick = async () => {
    if (form === 'item') {
      navigate(`/items/${itemId}`);
    } else navigate('/activities');
  };

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      await api.value.delete({ id: valueId });
      setLoading(false);
      navigate(`/items/${itemId}`);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);
      await api.value.update({ id: valueId, value });
      setLoading(false);
      navigate(`/items/${itemId}`);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title text={itemTitle} />
      <EditValue
        isText={isText}
        type={valueType}
        value={value}
        setValue={setValue}
      />
      <Grid item xs={12} textAlign="center">
        {valueId ? (
          <TwoButtons
            leftTitle={<FormattedMessage id="Delete" />}
            handleLeftClick={handleDeleteClick}
            rightTitle={<FormattedMessage id="Save" />}
            handleRightClick={handleSaveClick}
          />
        ) : (
          <TwoButtons
            leftTitle={<FormattedMessage id="Back" />}
            handleLeftClick={handleBackClick}
            rightTitle={<FormattedMessage id="Add" />}
            handleRightClick={handleAddClick}
          />
        )}
      </Grid>
      {error && <Error text={error} />}
      {!valueId && (
        <>
          {valueType === 'text' ? (
            <Grid
              item
              xs={12}
              textAlign={'center'}
              style={{ marginTop: '20px' }}
            >
              <DynamicFontText
                text={<FormattedMessage id="SelectExisting" />}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              overflowX: 'auto',
              maxHeight: '450px',
            }}
          >
            {titles.map((title) => (
              <Grid key={title} item xs={12} style={{ marginBottom: '4px' }}>
                <Paper
                  key={title}
                  component="button"
                  onClick={() => handleAddTitle(title)}
                  style={{ cursor: 'pointer' }}
                >
                  <ListItem>
                    <ListItemText
                      primary={title}
                      style={{ minWidth: '300px', textAlign: 'center' }}
                    />
                  </ListItem>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Value;

import React, { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/auth';
import { privateRoutes, publicRoutes } from '../routers/routes';
import Loader from '../components/Loader';
import { fetchApiMethods } from '../api/getMethods.js';

const Router = ({ setSynced }) => {
  const { isAuth, loading } = useContext(AuthContext);
  const location = useLocation();
  const checkSync = async () => {
    if (isAuth) {
      const api = await fetchApiMethods();
      const { syncInfo } = await api.sync.getInfo();
      if (syncInfo?.syncProcess) setSynced(false);
    }
    // console.log(setSynced); // Temp line
    // TODO DELETE OUTDATED
    // const { syncToServer, deviceId } = syncInfo;
    // if (!deviceId) setSynced(true);
    // else setSynced(!!syncToServer && deviceId);
  };

  useEffect(() => {
    checkSync();
  }, [location]);

  if (loading) return <Loader />;

  return isAuth ? (
    <Routes>
      {privateRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
    </Routes>
  );
};

export default Router;

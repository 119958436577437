/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';

import { fetchApiMethods } from '../api/getMethods';

import { AuthContext } from '../context/auth';
import { UserContext } from '../context/user.js';

const Menu = () => {
  const navigate = useNavigate();
  const { setIsAuth } = useContext(AuthContext);
  const { setDate } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [premium, setPremium] = useState();

  const [admin, setAdmin] = useState(false);
  const [userCount, setUserCount] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        if (
          localStorage.getItem('email') === process.env.REACT_APP_ADMIN_EMAIL
        ) {
          const userCount = await api.user.count();
          setUserCount(userCount.count);
          setAdmin(true);
        }
        const { status } = await api.user.getStatus();
        if (!status) setIsAuth(false);
        else setPremium(status.premium);

        setLoading(false);
      } catch (error) {
        // TEMP CODE
        console.log('TEMP ERROR');
        console.log(error);
        // =================
        setLoading(false);
        navigate(`/error`);
      }
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  const buttons = [
    { id: 'today', label: <FormattedMessage id="Items" />, disabled: false },
    {
      id: 'profile',
      label: <FormattedMessage id="Profile" />,
      disabled: false,
    },
    {
      id: 'settings',
      label: <FormattedMessage id="Settings" />,
      disabled: false,
    },
    {
      id: 'export',
      label: <FormattedMessage id="Export" />,
      disabled: premium ? false : true,
    },
    {
      id: 'import',
      label: <FormattedMessage id="Import" />,
      disabled: premium ? false : true,
    },
    { id: 'about', label: <FormattedMessage id="About" />, disabled: false },
    { id: 'logout', label: <FormattedMessage id="Logout" />, disabled: false },
  ];

  const handlerFunctions = new Map([
    [
      'today',
      () => {
        setDate(new Date());
        navigate('/activities');
      },
    ],
    [
      'profile',
      () => {
        navigate('/profile');
      },
    ],
    [
      'settings',
      () => {
        navigate('/settings');
      },
    ],
    [
      'export',
      () => {
        navigate('/export');
      },
    ],
    [
      'import',
      () => {
        navigate('/import');
      },
    ],
    [
      'about',
      () => {
        navigate('/about');
      },
    ],
    [
      'logout',
      () => {
        setIsAuth(false);
        localStorage.removeItem('token');
      },
    ],
  ]);

  const handleButtonClick = (id) => {
    handlerFunctions.get(id)();
  };

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="Menu" />} />
      {buttons.map((button) => (
        <Grid item xs={12} textAlign="center" key={button.id}>
          <Button
            key={button.id}
            disabled={button.disabled}
            onClick={() => handleButtonClick(button.id)}
          >
            {button.label}
          </Button>
        </Grid>
      ))}
      {admin && (
        <Grid item xs={12} textAlign={'center'}>
          users: {userCount}
        </Grid>
      )}
    </Grid>
  );
};

export default Menu;

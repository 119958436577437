/* eslint-disable no-undef */
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import ComboBox from '../components/ComboBox.jsx';
import EditButton from '../components/EditButton.jsx';
import Error from '../components/Error.jsx';
import Input from '../components/Input/Input.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import TwoButtons from '../components/TwoButtons.jsx';

import equalPlainObjects from '../shared/utils/equalPlainObjects.js';
import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

import { fetchApiMethods } from '../api/getMethods';

const ItemEditing = () => {
  const intl = useIntl();
  const positive = intl.formatMessage({ id: 'Positive' });
  const negative = intl.formatMessage({ id: 'Negative' });

  const navigate = useNavigate();
  const { id } = useParams();
  const [api, setApi] = useState({});
  const [description, setDescription] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [disabledTarget, setDisabledTarget] = useState(false);
  const [initialItem, setInitialItem] = useState({});
  const [initialTitle, setInitialTitle] = useState('');
  const [initialValueType, setInitialValueType] = useState('');
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedAssessment, setSelectedAssessment] = useState(positive);
  const [selectedValueType, setSelectedValueType] = useState(
    intl.formatMessage({ id: 'number' }),
  );
  const [target, setTarget] = useState('');
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');
  const [errorTarget, setErrorTarget] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const existedItemsRef = useRef();
  const sectionListRef = useRef();
  const storageItemRef = useRef({});
  const textRef = useRef('');
  const typeListRef = useRef();
  const typesMapRef = useRef();

  const assessments = [positive, negative];

  const setStateOfTheTargetField = (value) => {
    if (typesMapRef.current.get(value) === 'text') {
      setDisabledTarget(true);
    } else {
      setDisabledTarget(false);
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        storageItemRef.current = JSON.parse(localStorage.getItem('item'));
        const api = await fetchApiMethods();
        setApi(api);
        const { items } = await api.item.findByUser();
        const { types } = await api.item.getValueType();

        const typesTranslationsMap = new Map();
        typeListRef.current = types.map((type) => {
          const translation = intl.formatMessage({ id: type });
          typesTranslationsMap.set(translation, type);
          return translation;
        });
        typesMapRef.current = typesTranslationsMap;
        textRef.current = intl.formatMessage({ id: 'text' });

        const { sections } = await api.section.findByUser();
        const sectionTitles = sections
          ? sections.map((data) => data.title)
          : ['None'];
        sectionListRef.current = sectionTitles;
        setSections(sections);
        existedItemsRef.current = items.map((item) => item.title);
        const item = items.find((item) => item.id === id);
        if (id && item) {
          setEditMode(true);
          const {
            title,
            description,
            target,
            sectionId,
            valueAssessment,
            valueType,
          } = item;
          setInitialItem({
            title,
            description,
            target,
            sectionId,
            valueAssessment,
            valueType,
          });
          if (sectionId !== null) {
            const selectedSection = sections.find(
              (section) => section.id === item.sectionId,
            );
            const sectionTitle = selectedSection.title;
            setSelectedSection(sectionTitle);
          }

          const intlType = intl.formatMessage({ id: valueType });
          const intlAssessment = valueAssessment === true ? positive : negative;
          setSelectedAssessment(intlAssessment);

          description && setDescription(description);
          target && setTarget(target);
          title && setTitle(title) && setInitialTitle(title);
          valueType && setInitialValueType(intlType);
          valueType && setSelectedValueType(intlType);
          valueAssessment && setSelectedAssessment(intlAssessment);

          setStateOfTheTargetField(intlType);
        }

        if (storageItemRef.current !== null) {
          storageItemRef.current.description &&
            setDescription(storageItemRef.current.description);
          storageItemRef.current.target &&
            setTarget(storageItemRef.current.target);
          storageItemRef.current.title &&
            setTitle(storageItemRef.current.title) &&
            setInitialTitle(storageItemRef.current.title);
          storageItemRef.current.selectedAssessment &&
            setSelectedAssessment(storageItemRef.current.selectedAssessment);
          storageItemRef.current.selectedValueType &&
            setSelectedValueType(storageItemRef.current.selectedValueType);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(errorMessageHandler(error));
      }
    }

    fetchData();
  }, []);

  const handleAssessmentChange = (event) =>
    setSelectedAssessment(event.target.value);
  const handleCancelClick = () => {
    localStorage.removeItem('item');
    editMode ? navigate(`/items/${id}`) : navigate('/activities');
  };

  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleTitleChange = (event) => setTitle(event.target.value);
  const handleSectionChange = (event) => setSelectedSection(event.target.value);
  const handleSectionEditChange = () => {
    localStorage.setItem(
      'item',
      JSON.stringify({
        title,
        description,
        target,
        selectedValueType,
        selectedAssessment,
        editMode,
      }),
    );
    navigate(`/sections/${id}`);
  };
  const handleTargetChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setTarget(value);
      setError('');
    } else {
      setErrorTarget(<FormattedMessage id="TargetAsNumber" />);
      return;
    }
  };
  const handleValueTypeChange = (event) => {
    const value = event.target.value;
    setStateOfTheTargetField(value);
    setSelectedValueType(value);
  };
  const handleSaveClick = async () => {
    setError('');
    setErrorTarget('');
    setErrorTitle('');
    const sectionTitle = sectionListRef.current.find(
      (item) => item === selectedSection,
    );
    const section =
      sectionTitle &&
      sections.find((section) => section.title === sectionTitle);
    const sectionId = (section && section.id) || null;
    const valueAssessment = selectedAssessment === positive ? true : false;
    const valueType = selectedValueType;

    if (
      editMode &&
      ((valueType !== initialValueType && valueType === textRef.current) ||
        (initialValueType === textRef.current && valueType !== textRef.current))
    ) {
      setError(<FormattedMessage id="TypeValueConsistent" />);
      return;
    }

    if (!title) {
      setErrorTitle(<FormattedMessage id="RequiredField" />);
      return;
    }

    if (
      existedItemsRef.current.includes(title) &&
      !editMode &&
      title !== initialTitle
    ) {
      setErrorTitle(<FormattedMessage id="AlreadyExists" />);
      return;
    }

    if (title.length > 18) {
      setErrorTitle(<FormattedMessage id="TitleLength" />);
      return;
    }

    setLoading(true);
    try {
      const item = {
        title,
        description,
        target,
        sectionId,
        valueAssessment,
        valueType: typesMapRef.current.get(valueType),
      };

      localStorage.removeItem('item');

      if (editMode) {
        if (!equalPlainObjects(initialItem, item)) {
          await api.item.update({ id, ...item });
        }
        navigate(`/items/${id}`);
      } else {
        await api.item.create(item);
        navigate('/activities');
      }
    } catch (e) {
      setLoading(false);
      navigate(`/error/${e.message}`);
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="ItemForm" />} />
      <Grid item xs={12} textAlign="center">
        <Input
          id="itemTitle"
          label={<FormattedMessage id="ItemTitle" />}
          value={title}
          handleError={errorTitle}
          onChange={handleTitleChange}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Input
          id="itemDescription"
          label={<FormattedMessage id="ItemDescription" />}
          value={description}
          onChange={handleDescriptionChange}
          required={false}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Input
          id="itemTargetValue"
          label={<FormattedMessage id="ItemTargetValue" />}
          value={target}
          handleError={errorTarget}
          onChange={handleTargetChange}
          disabled={disabledTarget}
          required={false}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <FormControl sx={{ m: 1, minWidth: 247 }} size="small">
          <InputLabel id="select-sections">
            {<FormattedMessage id="Section" />}
          </InputLabel>
          <Select
            labelId="select-sections"
            id="select-sections-small"
            value={selectedSection}
            label="Sections"
            onChange={handleSectionChange}
            MenuProps={{
              sx: {
                '& .MuiMenuItem-root.Mui-selected': {
                  fontWeight: 'bold',
                  fontSize: '16px',
                },
              },
            }}
          >
            <MenuItem value="">
              <em>{<FormattedMessage id="None" />}</em>
            </MenuItem>
            {sections.map((section, index) => (
              <MenuItem
                key={index}
                value={section.title}
                sx={{
                  fontWeight:
                    selectedSection === section.title ? 'bold' : 'normal',
                }}
              >
                {section.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <EditButton handleEditClick={handleSectionEditChange} />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <ComboBox
          title={<FormattedMessage id="Type" />}
          options={loading ? [] : typeListRef.current || []}
          value={selectedValueType}
          handleComboBoxChange={handleValueTypeChange}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <ComboBox
          title={<FormattedMessage id="Assessment" />}
          options={loading ? [] : assessments || []}
          value={selectedAssessment}
          handleComboBoxChange={handleAssessmentChange}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <TwoButtons
          leftTitle={<FormattedMessage id="Back" />}
          handleLeftClick={handleCancelClick}
          rightTitle={<FormattedMessage id="Save" />}
          handleRightClick={handleSaveClick}
        />
      </Grid>
      {error && <Error text={error} />}
    </Grid>
  );
};

export default ItemEditing;

/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Error from '../components/Error.jsx';
import Item from '../components/Item.jsx';
import Loader from '../components/Loader.jsx';
import SectionTitle from '../components/SectionTitle.jsx';
import Title from '../components/Title.jsx';
import TwoButtons from '../components/TwoButtons';
import errorMessageHandler from '../shared/utils/errorMessageHandler.js';
import { fetchApiMethods } from '../api/getMethods';
import { UserContext } from '../context/user.js';

const Today = () => {
  const navigate = useNavigate();
  const { date, setDate } = useContext(UserContext);
  const [api, setApi] = useState({});
  const [error, setError] = useState('');
  const [items, setItems] = useState([]);
  const [nonSectionItems, setNonSectionItems] = useState([]);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayDate, setDisplayDate] = useState(date.toLocaleDateString());

  const getOffsetDate = (date, diff) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + diff);
    setDate(currentDate);
    setDisplayDate(currentDate.toLocaleDateString());
    return currentDate;
  };

  async function fetchItems(date) {
    try {
      const noSectionItems = [];
      const api = await fetchApiMethods();
      setApi(api);

      const { sections } = await api.section.findByUser();
      const { items } = await api.item.findByUser();
      const { values } = await api.value.getByDate({ date: date.getTime() });
      const itemIds = items.map((item) => parseInt(item.id));
      const { detailsList } = await api.item.getDetails({ itemIds });
      items.map((item) => {
        if (item.sectionId === null) noSectionItems.push(item);
        const itemDetails =
          detailsList && detailsList.filter((x) => x.itemId === item.id);
        if (itemDetails) {
          if (itemDetails.length === 1) {
            item.latestAt = itemDetails[0].latestAt;
          } else if (itemDetails.length > 1) {
            const latestAtList = itemDetails.map((x) =>
              new Date(x.latestAt).getTime(),
            );
            const maxLatestAt = Math.max(...latestAtList);
            item.latestAt = new Date(maxLatestAt).toISOString();
          }
        }
        if (item.valueType === 'text') {
          const valueList = values.filter((v) => v.itemId === item.id);
          item.value = -1;
          if (valueList.length)
            item['valueTitle'] = valueList[valueList.length - 1].value;
          return item;
        }
        const totalValue = values
          .filter((v) => v.itemId === item.id)
          .map((v) => parseInt(v.value))
          .reduce((total, value) => total + value, 0);
        item.value = totalValue !== 0 && totalValue;
        return item;
      });
      setLoading(false);
      return { items, sections, noSectionItems };
    } catch (error) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(error));
      return [];
    }
  }

  async function refreshItemsUI(date) {
    const { items, sections, noSectionItems } = await fetchItems(date);
    setItems(items);
    setSections(sections);
    setNonSectionItems(noSectionItems);
  }

  useEffect(() => {
    async function fetchData() {
      await refreshItemsUI(date);
    }
    fetchData();
  }, []);

  const handleInfoClick = async (item) => {
    if (item.valueType === 'text') {
      navigate(`/subitems/${item.id}/${item.title}`);
    } else {
      navigate(`/detail/${item.id}/${item.title}`);
    }
  };

  const handleLeftClick = async () => {
    try {
      setLoading(true);
      await refreshItemsUI(getOffsetDate(date, -1));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
      return [];
    }
  };

  const handleRightClick = async () => {
    try {
      setLoading(true);
      await refreshItemsUI(getOffsetDate(date, 1));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
      return [];
    }
  };

  const handleInvisibleClick = async (item) => {
    try {
      setLoading(true);
      await api.item.update({
        id: item.id,
        target: item.target,
        visible: false,
      });
      const updatedItems = items.filter((x) => x.id !== item.id);
      setItems(updatedItems);
      const updatedNonSectionItems = nonSectionItems.filter(
        (x) => x.id !== item.id,
      );
      setNonSectionItems(updatedNonSectionItems);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
      return [];
    }
  };

  const handleModalClick = async (selectedDate) => {
    try {
      setLoading(true);
      const currentDate = new Date(selectedDate);
      currentDate.setDate(currentDate.getDate());
      setDate(currentDate);
      setDisplayDate(currentDate.toLocaleDateString());
      await refreshItemsUI(currentDate);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title
        isModal={true}
        inputDate={date}
        text={displayDate}
        arrowButtons={true}
        handleLeftClick={handleLeftClick}
        handleRightClick={handleRightClick}
        handleGoDate={handleModalClick}
      />
      {items.length ? (
        <Grid item xs={12} textAlign="center">
          <div style={{ height: '70vh', overflow: 'auto' }}>
            {nonSectionItems.map((item) => (
              <Item
                key={item.id}
                assessment={item.valueAssessment}
                current={item.value}
                target={item.target !== 0 && item.target}
                title={item.valueTitle || item.title}
                valueLatestAt={item.latestAt}
                handleAddClick={() =>
                  navigate(
                    `/values/${item.id}/${item.title}/${item.valueType}/activities/${date}`,
                  )
                }
                handleEditClick={() => navigate(`/items/${item.id}`)}
                handleInfoClick={() => handleInfoClick(item)}
                handleVisibleClick={() => handleInvisibleClick(item)}
              />
            ))}
            {sections.map((section) => {
              const sectionItems = items.filter(
                (item) => item.sectionId === section.id,
              );
              return (
                <div key={section.id}>
                  {sectionItems.length > 0 && (
                    <SectionTitle title={section.title} />
                  )}
                  {sectionItems.map((item) => (
                    <Item
                      key={item.id}
                      assessment={item.valueAssessment}
                      current={item.value}
                      target={item.target !== 0 && item.target}
                      title={item.valueTitle || item.title}
                      valueLatestAt={item.latestAt}
                      handleAddClick={() =>
                        navigate(
                          `/values/${item.id}/${item.title}/${item.valueType}/activities/${date}`,
                        )
                      }
                      handleEditClick={() => navigate(`/items/${item.id}`)}
                      handleInfoClick={() => handleInfoClick(item)}
                      handleVisibleClick={() => handleInvisibleClick(item)}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Title color="gray" text={<FormattedMessage id="NoItems" />} />
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <TwoButtons
          leftTitle={<FormattedMessage id="Back" />}
          handleLeftClick={() => navigate('/menu')}
          rightTitle={<FormattedMessage id="AddItem" />}
          handleRightClick={() => navigate('/edit-item')}
          iconButton={true}
          iconButtonTitle={<FormattedMessage id="HiddenItems" />}
          iconButtonHandler={() => navigate('/hidden')}
        />
      </Grid>
      {error && <Error text={error} />}
    </Grid>
  );
};

export default Today;
